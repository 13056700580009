html,
body,
#root {
  height: 100%;
  margin: 0;
  background-color: silver;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.Padre {
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: silver;

}

.Cuerpo{
  margin: 0 auto;
  padding: 2% 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* ########## NAV ########## */

.ContenedorNav {
  width: 100%;
  height: 8%;
  position: sticky!important;
  top: 0;
  z-index: 999;
}

.nav {
  height: 100%;
  background-color: #333;
  color: white;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  box-sizing: border-box;
  border-bottom: solid 3px white;
}

.nav .logo h1 {
  font-size: 1.8rem;
  text-align: left;
}

.h1nav,
.h1nav:hover,
.h1nav:active,
.h1nav:visited {
  color: white;
  text-decoration: none;
}

.version{
  font-size: .8rem;
  color: whitesmoke;
}

ul.nav-links {
  list-style-type: none;
  display: flex;
  gap: 20px;
  margin: 0;
}

ul.nav-user {
  list-style-type: none;
}

ul.nav-links li a {
  text-decoration: none;
  color: white;
  font-size: 1.1rem;
}

ul.nav-links li a:hover {
  color: #7fb5b5;
}

/* Estilos para el enlace "Hola, Usuario" */
.usuario-link a {
  color: #7fb5b5;
  font-size: 1.2rem;
  font-weight: bold;
}

/* Estilos para la versión de escritorio */
@media (min-width: 768px) {
  ul.nav-links {
    margin-left: auto;
  }

  ul.nav-links li:last-child {
    margin-left: 20px;
  }

  .usuario {
    font-weight: bold;
  }
}

/* Estilos para dispositivos pequeños (móviles) */
@media (max-width: 767px) {
  .ContenedorNav,
  .nav {
    height: unset;
  }

  .nav {
    flex-direction: column;
    align-items: center;
    padding: 1rem;
  }

  .logo h1 {
    text-align: center;
    margin-bottom: 10px;
  }

  ul.nav-links {
    width: 100%;
    justify-content: space-around;
    margin-top: 10px;
    display: block; /* Cambia la disposición a bloque en móviles */
  }

  ul.nav-links li {
    padding: 10px 0;
    text-align: center;
  }

  /* Aquí se cambia el comportamiento de los enlaces de usuario */
  ul.nav-user {
    width: 100%;
    text-align: center; /* Asegura que "Hola, Usuario" se alinee en el centro */
    margin-top: 15px;
  }

  ul.nav-user li {
    padding: 10px 0;
  }

  ul.nav-links li a {
    font-size: 1.2rem; /* Aumenta el tamaño de fuente en móviles */
  }
}

/* ########## INICIO ########## */

/*Espacio hacia abajo*/
.espacio {
  margin-bottom: 10%;
}

.titulo1 {
  font-size: clamp(20px, 5vw, 80px);
  line-height: 1;
  display: inline-block;
  color: #fff;
  z-index: 2;
  letter-spacing: 10px;
  padding-bottom: 10px;

  /* Bright things in dark environments usually cast that light, giving off a glow */
  filter: drop-shadow(0 1px 3px);
}

/* Estilos para dispositivos pequeños (móviles) */
@media (max-width: 767px) {
  .titulo1 {
    font-size: clamp(20px, 8vw, 90px);
    letter-spacing: normal;
    padding-bottom: 20px;
  }
}

/*Efecto glich*/
.SeccionGlich {
  padding: 10px;
}

.hero {
  font-size: clamp(40px, 10vw, 100px);
  line-height: 1;
  display: inline-block;
  color: #fff;
  z-index: 2;
  letter-spacing: 10px;

  /* Bright things in dark environments usually cast that light, giving off a glow */
  filter: drop-shadow(0 1px 3px);
}

.layers {
  position: relative;
}

.layers::before,
.layers::after {
  content: attr(data-text);
  position: absolute;
  width: 100%;
  z-index: -1;
}

.layers::before {
  top: 10px;
  left: 15px;
  color: #e0287d;
}

.layers::after {
  top: 5px;
  left: -10px;
  color: #1bc7fb;
}

.single-path {
  clip-path: polygon(
    0% 12%,
    53% 12%,
    53% 26%,
    25% 26%,
    25% 86%,
    31% 86%,
    31% 0%,
    53% 0%,
    53% 84%,
    92% 84%,
    92% 82%,
    70% 82%,
    70% 29%,
    78% 29%,
    78% 65%,
    69% 65%,
    69% 66%,
    77% 66%,
    77% 45%,
    85% 45%,
    85% 26%,
    97% 26%,
    97% 28%,
    84% 28%,
    84% 34%,
    54% 34%,
    54% 89%,
    30% 89%,
    30% 58%,
    83% 58%,
    83% 5%,
    68% 5%,
    68% 36%,
    62% 36%,
    62% 1%,
    12% 1%,
    12% 34%,
    60% 34%,
    60% 57%,
    98% 57%,
    98% 83%,
    1% 83%,
    1% 53%,
    91% 53%,
    91% 84%,
    8% 84%,
    8% 83%,
    4% 83%
  );
}

.opacity {
  animation: opacity 5s step-end infinite;
}

@keyframes opacity {
  0% {
    opacity: 0.1;
  }

  5% {
    opacity: 0.7;
  }

  30% {
    opacity: 0.4;
  }

  45% {
    opacity: 0.6;
  }

  76% {
    opacity: 0.4;
  }

  90% {
    opacity: 0.8;
  }

  1%,
  7%,
  33%,
  47%,
  78%,
  93% {
    opacity: 0;
  }
}

.font {
  animation: font 7s step-end infinite;
}

@keyframes font {
  0% {
    font-weight: 100;
    color: #e0287d;
    filter: blur(3px);
  }

  20% {
    font-weight: 500;
    color: #fff;
    filter: blur(0);
  }

  50% {
    font-weight: 300;
    color: #1bc7fb;
    filter: blur(2px);
  }

  60% {
    font-weight: 700;
    color: #fff;
    filter: blur(0);
  }

  90% {
    font-weight: 500;
    color: #e0287d;
    filter: blur(6px);
  }
}

.glitch span {
  animation: paths 5s step-end infinite;
}

.glitch::before {
  animation: paths 5s step-end infinite, opacity 5s step-end infinite,
    font 8s step-end infinite, movement 10s step-end infinite;
}

.glitch::after {
  animation: paths 5s step-end infinite, opacity 5s step-end infinite,
    font 7s step-end infinite, movement 8s step-end infinite;
}

.hero-container {
  position: relative;
  padding: 200px 0;
  text-align: center;
}

.environment {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.5;
  filter: blur(5px);
}

/*Botón ranking*/
/* Estilo para el botón */
.BotonRanking {
  background-color: #333; /* Fondo oscuro */
  color: white; /* Texto blanco */
  border: 2px solid #555; /* Borde sutil */
  border-radius: 8px; /* Bordes redondeados */
  padding: 12px 20px; /* Espaciado interior */
  font-size: 16px; /* Tamaño de fuente */
  font-weight: bold; /* Texto en negrita */
  text-transform: uppercase; /* Texto en mayúsculas */
  cursor: pointer; /* Cambia el cursor a mano cuando pasa sobre el botón */
  transition: all 0.3s ease; /* Animación suave al pasar el ratón */
  text-decoration: none; /* Quita la subrayado del enlace */
  margin-bottom: 40px;
}

/* Efecto al pasar el ratón por encima */
.BotonRanking:hover {
  background-color: #555; /* Fondo ligeramente más claro */
  border-color: #888; /* Borde más claro */
  transform: translateY(-2px); /* Efecto de movimiento hacia arriba */
}

/* Efecto al hacer clic */
.BotonRanking:active {
  background-color: #444; /* Fondo aún más oscuro */
  border-color: #666; /* Borde más oscuro */
  transform: translateY(1px); /* Efecto de "presionar" el botón */
}

/* ########## LOGIN ########## */

.ContenedorLogin {
  background-color: #333;
  color: white;
  border: 2px solid #555;
  border-radius: 8px;
  padding: 20px;
  width: 60%;
  box-sizing: border-box; /* Para incluir padding en el cálculo del ancho */
  transition: all 0.3s ease; /* Animación suave */
}

/* Estilos para dispositivos pequeños (móviles) */
@media (max-width: 767px) {
  .ContenedorLogin {
    width: 100%; /* Ancho completo */
  }
}

/*Botones*/
.BotonLogin {
  background-color: #333; /* Fondo oscuro */
  color: white; /* Texto blanco */
  border: 2px solid #555; /* Borde sutil */
  border-radius: 8px; /* Bordes redondeados */
  padding: 12px 20px; /* Espaciado interior */
  font-size: 16px; /* Tamaño de fuente */
  font-weight: bold; /* Texto en negrita */
  text-transform: uppercase; /* Texto en mayúsculas */
  cursor: pointer; /* Cambia el cursor a mano cuando pasa sobre el botón */
  transition: all 0.3s ease; /* Animación suave al pasar el ratón */
  text-decoration: none; /* Quita la subrayado del enlace */
  margin-top: 10px; /* Espacio hacia arriba */
}

/* Efecto al pasar el ratón por encima */
.BotonLogin:hover {
  background-color: #555; /* Fondo ligeramente más claro */
  border-color: #888; /* Borde más claro */
  transform: translateY(-2px); /* Efecto de movimiento hacia arriba */
}

/* Efecto al hacer clic */
.BotonLogin:active {
  background-color: #444; /* Fondo aún más oscuro */
  border-color: #666; /* Borde más oscuro */
  transform: translateY(1px); /* Efecto de "presionar" el botón */
}

/*Inputs*/
.campoLogin {
  background-color: #333; /* Fondo oscuro */
  color: white; /* Texto blanco */
  border: 2px solid #555; /* Borde sutil */
  border-radius: 8px; /* Bordes redondeados */
  padding: 10px 15px; /* Espaciado interior */
  font-size: 16px; /* Tamaño de fuente */
  font-weight: bold; /* Texto en negrita */
  width: 100%; /* Ancho completo */
  box-sizing: border-box; /* Para incluir padding en el cálculo del ancho */
  transition: all 0.3s ease; /* Animación suave */
  margin-top: 10px; /* Espacio hacia arriba */
}

/* Efecto al pasar el ratón por encima */
.campoLogin:hover {
  border-color: #888; /* Borde más claro */
}

/* Efecto cuando el campo está enfocado (focus) */
.campoLogin:focus {
  background-color: #444; /* Fondo más oscuro cuando está enfocado */
  border-color: #ff9f00; /* Borde anaranjado brillante al enfocarse */
  outline: none; /* Elimina el contorno por defecto */
}

/*Mensaje de error*/
.msgErrorLogin {
  color: red;
  margin-top: 10px;
}

/*Espacio separador forms*/
.espaciomini {
  margin-top: 4%;
}

/* ########## PERFIL ########## */
.margenPerfil {
  margin-top: 10%;
}

.margenAdmin {
  margin-top: 15px;
}

.centro {
  width: 100%;
  text-align: center;
  margin-bottom: 15px;
}

.sinMargin{
  margin: unset!important;
}

.ContenedorPerfil {
  background-color: #333;
  color: white;
  border: 2px solid #555;
  border-radius: 8px;
  padding: 20px;
  width: 60%;
  box-sizing: border-box; /* Para incluir padding en el cálculo del ancho */
  transition: all 0.3s ease; /* Animación suave */
}

.ContenedorPerfil h2 {
  margin-bottom: 10px;
}

.ContenedorLogin p {
  margin-top: 10px;
}

.SeccionPerfil {
  border: solid 2px white;
  border-radius: 8px;
  padding: 20px;
}

.SeccionPerfil h2 {
  text-decoration-line: underline;
  text-decoration-style: dashed;
  text-decoration-color: whitesmoke;
}

.mas {
  text-decoration-color: green !important;
}

.menos {
  text-decoration-color: red !important;
}

.InputPerfil {
  background-color: #333; /* Fondo oscuro */
  color: white; /* Texto blanco */
  border: 2px solid #555; /* Borde sutil */
  border-radius: 8px; /* Bordes redondeados */
  padding: 10px 15px; /* Espaciado interior */
  font-size: 16px; /* Tamaño de fuente */
  font-weight: bold; /* Texto en negrita */
  width: 60%; /* Ancho completo */
  box-sizing: border-box; /* Para incluir padding en el cálculo del ancho */
  transition: all 0.3s ease; /* Animación suave */
  margin-top: 10px; /* Espacio hacia arriba */
}

.CampoPerfil {
  background-color: #333; /* Fondo oscuro */
  color: white; /* Texto blanco */
  border: 2px solid #555; /* Borde sutil */
  border-radius: 8px; /* Bordes redondeados */
  padding: 10px 15px; /* Espaciado interior */
  font-size: 16px; /* Tamaño de fuente */
  font-weight: bold; /* Texto en negrita */
  width: 100%; /* Ancho completo */
  box-sizing: border-box; /* Para incluir padding en el cálculo del ancho */
  transition: all 0.3s ease; /* Animación suave */
  margin-top: 10px; /* Espacio hacia arriba */
}

/* Efecto al pasar el ratón por encima */
.CampoPerfil:hover,
.InputPerfil:hover {
  border-color: #888; /* Borde más claro */
}

/* Efecto cuando el campo está enfocado (focus) */
.CampoPerfil:focus,
.InputPerfil:focus {
  background-color: #444; /* Fondo más oscuro cuando está enfocado */
  border-color: #ff9f00; /* Borde anaranjado brillante al enfocarse */
  outline: none; /* Elimina el contorno por defecto */
}

.BotonActualizar {
  width: 38%;
  background-color: #333; /* Fondo oscuro */
  color: white; /* Texto blanco */
  border: 2px solid #555; /* Borde sutil */
  border-radius: 8px; /* Bordes redondeados */
  padding: 10px 20px; /* Espaciado interior */
  font-size: 16px; /* Tamaño de fuente */
  font-weight: bold; /* Texto en negrita */
  text-transform: uppercase; /* Texto en mayúsculas */
  cursor: pointer; /* Cambia el cursor a mano cuando pasa sobre el botón */
  transition: all 0.3s ease; /* Animación suave al pasar el ratón */
  text-decoration: none; /* Quita la subrayado del enlace */
  margin-left: 2%;
}

/* Efecto al pasar el ratón por encima */
.BotonActualizar:hover {
  background-color: #555; /* Fondo ligeramente más claro */
  border-color: #888; /* Borde más claro */
  transform: translateY(-2px); /* Efecto de movimiento hacia arriba */
}

/* Efecto al hacer clic */
.BotonActualizar:active {
  background-color: #444; /* Fondo aún más oscuro */
  border-color: #666; /* Borde más oscuro */
  transform: translateY(1px); /* Efecto de "presionar" el botón */
}

.BotonCambiarPassword {
  background-color: #333; /* Fondo oscuro */
  color: white; /* Texto blanco */
  border: 2px solid #555; /* Borde sutil */
  border-radius: 8px; /* Bordes redondeados */
  padding: 12px 20px; /* Espaciado interior */
  font-size: 16px; /* Tamaño de fuente */
  font-weight: bold; /* Texto en negrita */
  text-transform: uppercase; /* Texto en mayúsculas */
  cursor: pointer; /* Cambia el cursor a mano cuando pasa sobre el botón */
  transition: all 0.3s ease; /* Animación suave al pasar el ratón */
  text-decoration: none; /* Quita la subrayado del enlace */
  margin-top: 10px; /* Espacio hacia arriba */
  width: 100%;
}

/* Efecto al pasar el ratón por encima */
.BotonCambiarPassword:hover {
  background-color: #555; /* Fondo ligeramente más claro */
  border-color: #888; /* Borde más claro */
  transform: translateY(-2px); /* Efecto de movimiento hacia arriba */
}

/* Efecto al hacer clic */
.BotonCambiarPassword:active {
  background-color: #444; /* Fondo aún más oscuro */
  border-color: #666; /* Borde más oscuro */
  transform: translateY(1px); /* Efecto de "presionar" el botón */
}

.espacioperfil {
  margin-top: 29px;
  height: 1px;
}

.ContenedorFecha {
  width: 100%;
}

.InputFecha {
  width: 47.5% !important;
}

.EspacioFechas {
  margin-left: 5%;
}

.BotonGenerar {
  width: 100%;
  margin-top: 20px;
  margin-left: unset !important;
}
.ErrorGenerar {
  color: red;
  margin-top: 10px;
}

.botonTab{
  padding: 5%;
  border-radius: 8px;
  background-color: #7fb5b5; /* Fondo oscuro */
  border: 2px solid white; /* Borde sutil */
}

.linkTelegram{
  color: #7fb5b5;
  text-decoration: none;
}

.linkTelegram:hover{
  cursor: pointer;
}

.linkTelegram:active{
  color: #7fb5b5;
} 

.linkTelegram:visited{
  color: #7fb5b5;
}

/* Estilos para dispositivos pequeños (móviles) */
@media (max-width: 767px) {
  .ContenedorPerfil,
  .CampoPerfil,
  .InputPerfil {
    width: 100%; /* Ancho completo */
  }

  .BotonActualizar {
    margin-top: 5px; /* Espacio hacia arriba */
    margin-left: 0; /* Centra el botón */
    width: 100%;
  }

  .CampoPerfil {
    margin-top: 15px;
  }
}

/* ########## RANKING ########## */
.TituloRanking {
  width: 100%;
  text-align: center;
  font-size: 2rem;
  margin-bottom: 20px;
}

.TituloFecha {
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.ContenedorRanking {
  background-color: #333;
  color: white;
  border: 2px solid #555;
  border-radius: 8px;
  padding: 20px;
  width: 60%;
  box-sizing: border-box; /* Para incluir padding en el cálculo del ancho */
  transition: all 0.3s ease; /* Animación suave */
  text-align: center;
}

.ContenedorRanking h2 {
  margin-bottom: 10px;
}

.marginTop{
  margin-top: 20px;
}

.ContenedorTabla {
  padding: 20px;
  border: solid 2px white;
  border-radius: 8px;
  margin-top: 15px;
}

.TablaRanking {
  border-collapse: separate; /* Permite bordes redondeados */
  border: solid 1px #ddd; /* Borde sutil */
  border-spacing: 0;
  border-radius: 10px;
  overflow: hidden; /* Asegura que el redondeado se aplique correctamente */
  width: 100%; /* Ajustar al contenedor */
}

.TablaRanking tr th,
.TablaRanking tr td {
  padding: 12px;
  text-align: center;
  border: 1px solid #ddd;
}

.TablaRanking tr th {
  background-color: #7fb5b5;
  color: white;
}

/* Estilos para dispositivos pequeños (móviles) */
@media (max-width: 767px) {
  .ContenedorRanking {
    width: 100%;
  }

  .MargenMovil{
    margin-top: 20%;
  }
}


/* ########## VOTAR ########## */
.ContenedorVotar {
  background-color: #333;
  color: white;
  border: 2px solid #555;
  border-radius: 8px;
  padding: 20px;
  width: 60%;
  box-sizing: border-box; /* Para incluir padding en el cálculo del ancho */
  transition: all 0.3s ease; /* Animación suave */
}

.SeccionVotar {
  border: solid 2px white;
  border-radius: 8px;
  padding: 20px;
}

.TablaVotar{
  border-collapse: separate; /* Permite bordes redondeados */
  border: solid 1px #ddd; /* Borde sutil */
  border-spacing: 0;
  border-radius: 10px;
  overflow: hidden; /* Asegura que el redondeado se aplique correctamente */
  width: 100%; /* Ajustar al contenedor */
}

.TablaVotar tr th,
.TablaVotar tr td {
  padding: 12px;
  text-align: center;
  border: 1px solid #ddd;
}

.TablaVotar tr th {
  border-bottom: unset;
}

.TablaVotar tr th {
  background-color: #7fb5b5;
  color: white;
}

.BotonVoto {
  color: white; /* Texto blanco */
  border: 2px solid #555; /* Borde sutil */
  border-radius: 8px; /* Bordes redondeados */
  padding: 5px 10px; /* Espaciado interior */
  font-weight: bold; /* Texto en negrita */
  text-transform: uppercase; /* Texto en mayúsculas */
  cursor: pointer; /* Cambia el cursor a mano cuando pasa sobre el botón */
  transition: all 0.3s ease; /* Animación suave al pasar el ratón */
  text-decoration: none; /* Quita la subrayado del enlace */
}

.VotoMejor{
  background-color: #a4cd9d;
}

.VotoPeor{
  margin-left: 5px;
  background-color: #ff6961;
}

.VotoMejor:hover{
  background-color: #89ab83;
}

.VotoPeor:hover{
  background-color: #af4a44;
}

.disabled {
  pointer-events: none;
  opacity: 0.6;
}

.verde {
  color: #bdecb6;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100%;
  text-align: center;
}

.rojo {
  color: #ff6961;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100%;
  text-align: center;
}

/* Estilos para dispositivos pequeños (móviles) */
@media (max-width: 767px) {
  .ContenedorVotar {
    width: 100%; /* Ancho completo */
  }
}





/* COLORES PARA LOS MENSAJES */
.mensajeError{
  margin-top: 10px;
}

.colorRojo {
  color: red!important;
}

.colorVerde {
  color: green!important;
}

.colorBlanco {
  color: white!important;
}